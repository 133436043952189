<template>
    <b-modal id="tokenModal" title="Generate Api Token">
        <b-container v-if="hasGenerated == false" style="text-align: center;">
            <b-alert show variant="warning">
                <h3>Warning</h3>
                <h4>This token will only be visible once.</h4>
                <h5>Please make a safe copy.</h5>
            </b-alert>
            <hr>
            <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                <b-button v-on:click="generate" size="lg" variant="outline-primary">Generate Api Token</b-button>
            </b-overlay>
        </b-container>
        <b-container v-else style="text-align: center;">
            <h3>Api Token</h3>
            <b-form-input ref="tokenInputField" v-model="token" readonly style="text-align:center; margin-bottom: 10px;"></b-form-input>
            <b-button variant="primary" v-on:click="copyTokenToClipboard">Copy To Clipboard</b-button>
        </b-container>
        <template #modal-footer>
            <div style="width: 100%;">
                <b-button v-on:click="close" style="float:right">Close</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { clientViewMethods, clientComputed} from '@/state/helpers.js'
export default {
    data(){
        return{
            busy: false,
            hasGenerated: false,
            token:'',
        }
    },
    computed:{
        ...clientComputed,
    },
    methods:{
        ...clientViewMethods,
        close(){
            this.$bvModal.hide('tokenModal');
        },
        generate(){
            this.busy = true;
            this.generateApiKey(this.selected_app.id)
            .then((data)=>{
                this.token = data.token;
                this.busy = false;
                this.hasGenerated = true;
            })
            .catch(()=>{
                this.busy = false;
            })
        },
        copyTokenToClipboard(){
            var input = this.$refs.tokenInputField;
            input.focus();
            input.select();
            this.$nextTick(()=>{
                var success = document.execCommand('copy');
                if(success){
                    alert('Copy success.');
                }
                else{
                    alert('Error while making copy');
                }
            })
        }
    },
    created(){

    },
    beforeDestroy(){

    }
}
</script>

<style>

</style>