<script>
import Layout from "../../layouts/main";
//import PageHeader from "@/components/page-header";
//import BuilderUI from '../../../components/builder/builderLayout.vue';
//import BuilderMain from '../../../components/builder/builderMain.vue';
import { 
    clientMethods, clientComputed, clientViewMethods, clientViewComputed,
} from '@/state/helpers'

import GenerateKeyModal from '@/components/builder/modals/generateToken.vue'


/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Dashboard",
    },
    components: {
        Layout,
        GenerateKeyModal
        //PageHeader,
        //BuilderUI,
        //BuilderMain
    },
    computed:{
        ...clientComputed,
        ...clientViewComputed,

        showDesignerOptions(){
            var show = false;
            if(this.selected_app != null){
                show = true;
            }
            return show;
        },

        showSaveButton(){
            if(this.newApp.name == '' || this.newApp.tag == ''){
                return false;
            }
            else{
                return true;
            }
        },
    },
    methods:{
        ...clientMethods,
        ...clientViewMethods,

        saveApp(){
            this.isSaving = true;
            this.saveNewApp(this.newApp)
            .then(()=>{
                this.newApp = { id: -1, name:'', tag:''};
                this.loadApps();
                this.isSaving = false;
                //this.$refs.newAppForm.hide(true);
                this.showAddApp = false
            })
            .catch(()=>{
                this.isSaving = false;
            })
        },

        showDesigner(type){
            this.isLoading = true;
            this.$eventHub.$emit('selectedDesignerMode', type);
        },
        resetOptions(){
            //*Reset selected app and designer_mode
            if(this.designer_mode != null){
                this.setDesignerMode(null);
            }
            if(this.selected_app != null){
                this.setSelectedApp(null)
            }
        },

        openGenerateKeyModal(){
            this.showGenerateModal = true;

            this.$nextTick(()=>{
                this.$bvModal.show('tokenModal');
                this.$root.$once('bv::modal::hidden',(event)=>{
                    if(event.type == 'hidden' && event.componentId == 'tokenModal'){
                        this.showGenerateModal = false;
                    }
                })
            });
        }
    },
    data() {
        return {
            showGenerateModal: false,

            showElement: 2,

            showAddApp: false,
            newApp:{
                id: -1,
                name: '',
                tag: '',
            },
            isSaving: false,
            isLoading: false,
        };
    },
    mounted(){
        this.resetOptions();
        this.$eventHub.$on('resetOptions', this.resetOptions);
    },
    beforeDestroy(){
        this.$eventHub.$off('resetOptions');
    }
};
</script>

<template>
    <Layout>
        <div v-if="showDesignerOptions" class="text-center animated fadeIn">
            <!--<PageHeader :title="title" :items="items" style="padding: 0px; margin: 0px; height:0px; width: 0px;" />-->
            <!--<BuilderUI v-if="showElement == 1" />
            <BuilderMain v-if="showElement == 2" />-->
            <b-row class="mt-4">
                <b-col cols="3"></b-col>
                <b-col cols="6">
                    <div class="card cardHover">
                        <b-overlay :show="isLoading">
                            <div class="card-body">
                                <h1 style="margin-top: 2rem;" class="">
                                    Select Designer Mode
                                </h1>
                                <b-row>
                                    <b-col cols="3"></b-col>
                                    <b-col cols="3">
                                        <b-button @click="showDesigner('report')" size="lg" variant="outline-primary" style="margin-top: 2rem;">
                                            <b-icon style="margin-right: 0.25em;" font-scale="2" icon="file-earmark-richtext"></b-icon>
                                                <strong>Reports</strong>
                                        </b-button>
                                    </b-col>
                                    <b-col cols="3">
                                        <b-button @click="showDesigner('views')" size="lg" variant="outline-primary" style="margin-top: 2rem;">
                                            <b-icon style="margin-right: 0.25em;" font-scale="2" icon="columns-gap"></b-icon>
                                                <strong>Views</strong>
                                        </b-button>
                                    </b-col>
                                    <b-col cols="3"></b-col>
                                </b-row>
                            </div>
                        </b-overlay>
                    </div>
                    <hr>
                    <div class="card cardHover">
                        <div class="card-body">
                            <h1 style="margin-top: 2rem;" class="">
                                Api Key
                            </h1>
                            <b-row>
                                <b-col cols="4"></b-col>
                                <b-col cols="4">
                                    <b-button @click="openGenerateKeyModal" size="lg" variant="outline-primary" style="margin-top: 2rem;">
                                        <b-icon style="margin-right: 0.25em;" font-scale="2" icon="key"></b-icon>
                                            <strong>Generate</strong>
                                    </b-button>
                                </b-col>
                                <b-col cols="4"></b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
                <b-col cols="3"></b-col>
            </b-row>
        </div>
        <div v-else class="text-center">
            
            <div v-if="showAddApp == false">
                <b-row class="mt-4">
                    <b-col cols="3"></b-col>
                    <b-col cols="6">
                        <div class="card cardHover">
                            <div class="card-body">
                                <h1 style="margin-top: 2rem;" class="mb-4">
                                    Select an <strong>app</strong> to get started.
                                </h1>
                                <h1 style="margin-top: 2rem;">
                                    or
                                </h1>
                                <b-button v-on:click="showAddApp = true;" class="addItemDropdown" size="lg" ref="newAppForm" variant="outline-primary" style="margin-top: 2rem;">
                                    <b-icon style="margin-right: 0.2em;" font-scale="2" icon="plus-square"></b-icon>
                                        Create New App
                                </b-button>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="3"></b-col>
                </b-row>
                <!--
                <h1 style="margin-top: 2rem;">
                    Select an <strong>app</strong> to get started.
                </h1>
                <h1 style="margin-top: 2rem;">
                    or
                </h1>
                <b-button v-on:click="showAddApp = true;" class="addItemDropdown" size="lg" ref="newAppForm" variant="outline-primary" style="margin-top: 2rem;">
                    <b-icon style="margin-right: 0.2em;" font-scale="2" icon="plus-square"></b-icon>
                        Create New App
                </b-button>
                -->
            </div>
            <div v-else>
                <b-row class="mt-4">
                    <b-col cols="3"></b-col>
                    <b-col cols="6">
                        <div class="card cardHover">
                            <div class="card-body">
                                <h1>Add New App</h1>
                                <b-form style="margin: 2rem; display: flex; align-items: center; justify-content: center;">
                                    <b-overlay style="padding: 6px; border: 1px solid lightgrey; border-radius: 6px;" :show="isSaving">
                                        <b-form-group submit="save">
                                            <b-row class="btnRow">
                                                <b-col style="text-align: left; margin-top: 0.5em;" cols="2">
                                                    <label for="">Name</label>
                                                </b-col>
                                                <b-col>
                                                    <b-form-input v-model="newApp.name"></b-form-input>
                                                </b-col>
                                            </b-row>
                                            <b-row class="btnRow">
                                                <b-col style="text-align: left; margin-top: 0.5em;" cols="2">
                                                    <label for="">Tag</label>
                                                </b-col>
                                                <b-col>
                                                    <b-form-input v-model="newApp.tag"></b-form-input>
                                                </b-col>
                                            </b-row>
                                        </b-form-group>
                                        <hr>
                                        <div style="text-align:right;">

                                            <b-button v-if="showSaveButton" v-on:click="saveApp" variant="success">Save</b-button>
                                            <!--<b-button v-else disabled v-on:click="saveApp" variant="success">Save</b-button>-->
                                            &nbsp;
                                            <b-button v-on:click="showAddApp = false;" variant="warning">Back</b-button>
                                        </div>
                                    </b-overlay>
                                </b-form>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="3"></b-col>
                </b-row>
                <!--<b-form style="margin-top: 2rem; display: flex; align-items: center; justify-content: center;">
                    <b-overlay style="padding: 6px; border: 1px solid black; border-radius: 6px;" :show="isSaving">
                        <b-form-group submit="save">
                            <b-row class="btnRow">
                                <b-col style="text-align: left; margin-top: 0.5em;" cols="2">
                                    <label for="">Name</label>
                                </b-col>
                                <b-col>
                                    <b-form-input v-model="newApp.name"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="btnRow">
                                <b-col style="text-align: left; margin-top: 0.5em;" cols="2">
                                    <label for="">Tag</label>
                                </b-col>
                                <b-col>
                                    <b-form-input v-model="newApp.tag"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-form-group>
                        <hr>
                        <div style="text-align:center;">

                            <b-button v-if="showSaveButton" v-on:click="saveApp" variant="success">Save</b-button>
                            <b-button v-else disabled v-on:click="saveApp" variant="success">Save</b-button>
                            &nbsp;
                            <b-button v-on:click="showAddApp = false;" variant="warning">Back</b-button>
                        </div>
                    </b-overlay>
                </b-form>-->
            </div>
        </div>
        <GenerateKeyModal v-if="showGenerateModal" />
    </Layout>
</template>

<style>

</style>
